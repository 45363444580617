<template>
  <div style="margin-top: 23%" class="text-center">
    <v-progress-circular indeterminate color="primary"></v-progress-circular>
    <zoomVideoCall/>
  </div>
</template>

<script>
/*eslint-disable*/
export default {
  components: {
    zoomVideoCall: () => import('./zoomVideoCall.vue')
  }
}
</script>

<style scoped>

</style>